// Dependências 
import { storeToRefs } from "pinia"
import { useSettingsStore } from "~/stores/settings"

// Middleware
export default defineNuxtRouteMiddleware(() => {
  // Pinia
  const settingsStore = useSettingsStore()
  const { simulatorSettings } = storeToRefs(settingsStore)

  const alfaAuth = simulatorSettings.value.token

  if (!alfaAuth) return navigateTo('/simulador-de-emprestimo')
})